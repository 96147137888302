import '../styles/index.scss';
import $ from "jquery";
import 'selectric';
import 'magnific-popup';
import Inputmask from "inputmask";
import ls from 'local-storage';
import _ from 'lodash';
import config from './config';

const queryString = window.location.search;
const urlParams = new URLSearchParams(queryString);
const cakereqid = urlParams.get('cake');

$(document)
    .ready(function () {

    });
// CONFIGS
var protocol = document.location.protocol;
var post_url = protocol + '//' + config.endpoint + '/formdov.php';
var params;

// If user leaves mid page 
$(window).on('beforeunload', function() {
    const url = window.location.href;
    if(!url.toLowerCase().includes('terms-of-use.html') && !url.toLowerCase().includes('privacy-policy.html') && !url.toLowerCase().includes('partners-list.html')) {
        setTimeout(function () {
            setTimeout(function () {
                showPopUp();
                $('.popup')
                    .removeClass('hide');
                $('.step.active')
                    .removeClass('active');
                $('.step.step-confirmation')
                    .addClass('active');
                $('.form-top .steps')
                    .empty();
                var exit_strategy = true;
                getClickWall(exit_strategy);
            }, 1500);
        }, 1);
        return 'Would you like to see rates from our top lenders?';
    }
});

/*
 * HELPERS
 */
function getUrlVars() {
    var vars = {};
    var parts = window.location.href.replace(/[?&]+([^=&]+)=([^&]*)/gi, function (m, key, value) {
        vars[key] = value;
    });
    return vars;
}

function getUrlParam(parameter, defaultvalue) {
    var urlparameter = defaultvalue;
    if (window.location.href.indexOf(parameter) > -1) {
        urlparameter = getUrlVars()[parameter];
    }
    return urlparameter;
}

function RGRCallBack(EventID, Count)
{
    if (EventID == null) {
        EventID = "A1B2C3";
    }
    if (Count == null) {
        Count = 28;
    }


    $.ajax({
        async: false,
        
        url: "//www.reallygreatrate.com/api/mao_log/",
        type: "GET",
        dataType: 'json',
        data: { 
            'EventID': EventID,
            'Count': Count
        },
        
                
    });
}

/*
 * Basic email validation
 */
function validEmail(email) {
    var pattern = /^([\w-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([\w-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$/;
    return $.trim(email)
        .match(pattern) ? true : false;
}
/*
 * Basic zipcode validation
 */
function isValidZipCode(zip) {
    return /^\d{5}(-\d{4})?$/.test(zip);
}

/*
 * Checks if value in array
 */
function isInArray(value, array) {
    return array.indexOf(value) > -1;
}

var get_params = function (search_string) {
    var parse = function (params, pairs) {
        var pair = pairs[0];
        var parts = pair.split('=');
        var key = decodeURIComponent(parts[0]);
        var value = decodeURIComponent(parts.slice(1)
            .join('='));

        // Handle multiple parameters of the same name
        if (typeof params[key] === "undefined") {
            params[key] = value;
        } else {
            params[key] = [].concat(params[key], value);
        }

        return pairs.length == 1 ? params : parse(params, pairs.slice(1));
    };

    // Get rid of leading ?
    return search_string.length == 0 ? {} : parse({}, search_string.substr(1)
        .split('&'));
};

/*
 * USER IP
 */
$.ajax({
    url: '//www.reallygreatrate.com/api/user/ip_address/',
    dataType: 'json',
    method: 'get',
    data: {},
    success: function (response) {
        $('#user_ip')
            .val(response['ip_address']);
    }
});

/*
 * MAGNIFIC POPUP
 */
$('.mfp-iframe')
    .each(function () { // the containers for all your galleries
        $(this)
            .magnificPopup({
                type: 'iframe',
                mainClass: 'iframe-popup'
            });
    });

$('.mfp-inline')
    .each(function () { // the containers for all your galleries
        $('.mfp-inline')
            .magnificPopup({
                type: 'inline'
            });
    });

/*
    POPUP
*/
function showPopUp() {
    setTimeout(function () {
        $('.step-form .step.active .radio-button a')
            .first()
            .focus();
    }, 301);
    $('html')
        .addClass('popup-open');
    $('.popup')
        .fadeIn();
    $(window)
        .scrollTop(0);
}

function myAdOptimizerTracker() {
    var clickwallPercentage;
    var value = window.location.hostname;
    console.log("Value: " + value);
    $.ajax({
        async: false,

        url: '//www.reallygreatrate.com/api/clickwall_percentage/',
        type: "GET",
        dataType: 'json',
        data: { 'website': value },
        success: function (response) {
            clickwallPercentage = response;
        }
                
    });

    console.log(clickwallPercentage);

}

function getClickWall(exit_strategy = false) {

    function sendMaoParams() {
        var maoZip = $('input[name="zip"]').val();
        var maoState = $('[name=state]').val();
        var maoCredit = $('[name="field_6"]:checked').val();
        var maoLoan = $('[name="field_4"]').val();
        var maoHomeValue = $('[name="field_2"]').val();
        var maoPropertyType = $('[name="field_1"]:checked').val();
        var maoMilitary = $('[name="field_28"]').val();
        var maoPub = $('[name="publisher_id"]').val();
        var maoJornaya = $('[name="field_31"]').val();
        var maoCashOut = $('[name="cash_out"]').val();

        // MAO PII added  firstname lastname email phone address city 
        var maoFname = $('input[name="first_name"]').val();
        maoFname = encodeURIComponent(maoFname);
        var maoLname = $('input[name="last_name"]').val();
        maoLname = encodeURIComponent(maoLname);
        var maoEmail = $('input[name="email_address"]').val();
        var maoPhone = $('input[name="home_phone"]').val();
        maoPhone = $.trim(maoPhone);
        maoPhone = maoPhone.replace(/[^0-9]/g, '');
        maoPhone = encodeURIComponent(maoPhone);
        var maoCity = $('input[name="city"]').val();
        maoCity = encodeURIComponent(maoCity);
        var maoAddress = $('input[name="street_address"]').val();
        maoAddress = encodeURIComponent(maoAddress) + "," + encodeURIComponent(" " + $('input[name="city"]').val()) + "," + encodeURIComponent(" " + $('[name=state]').val() + " " + $('input[name="zip"]').val());


        if (maoPropertyType == 'Condominium') {
            maoPropertyType = 'Condo%2FTownhome';
        } else if (maoPropertyType == 'Multi-Family Dwelling Unit') {
            maoPropertyType = 'Multi-Unit';
        } else if (maoPropertyType == 'Mobile Home') {
            maoPropertyType = 'Mobile';
        }

        if (maoCashOut == 'Maybe') {
            maoCashOut = 'Yes';
        }

        if(maoMilitary == 'N') {
            maoMilitary = 'No';
        }

        if(maoMilitary == 'Y') {
            maoMilitary = 'Yes';
        }

        if (exit_strategy == true) {
            var maoCashOut = '';
            var maoZip = '';
            var maoState = '';
            var maoCredit = '';
            var maoLoan = '';
            var maoHomeValue = '';
            var maoPropertyType = '';
            var maoMilitary = '';

            // MAO PII added 

            var maoFname = '';
            var maoLname = '';
            var maoEmail = '';
            var maoPhone = '';
            var maoAddress = '';
            var maoCity = '';
        }

        var maoParams = 
            "?zipcode=" + maoZip + 
            "&SourceID=" + maoPub +
            "&State=" + maoState +
            "&creditscore=" + maoCredit +
            "&loanbalance=" + maoLoan +
            "&propertyvalue=" + maoHomeValue +
            "&PropertyType=" + maoPropertyType +
            "&MilitaryStatus=" + maoMilitary +
            "&ExtClickID=" + maoJornaya +
            "&CashOut=" + maoCashOut +
            "&MediaChannel=" + "" + 
            "&EmploymentStatus=" + "" + 
            "&PropertyUse=" + "" + 
            "&FName=" + maoFname +
            "&LName=" + maoLname +
            "&Email=" + maoEmail +
            "&Phone=" + maoPhone + 
            "&Address=" + maoAddress +
            "&City=" + maoCity;

        window.history.replaceState(null, null, maoParams);
    }

    function create_UUIDLanding(){
                       var dt = new Date().getTime();
                       var uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
                           var r = (dt + Math.random()*16)%16 | 0;
                           dt = Math.floor(dt/16);
                           return (c=='x' ? r :(r&0x3|0x8)).toString(16);
                       });
                       return uuid;
                   }

               var LandingID = 14;
               var LandingEventID = create_UUIDLanding();

               window.onerror = function (errorMsg, url, line) 
               {
                   
                   //Add logging logic here
                   $.ajax({
                       async: false,
                       
                       url: "//www.reallygreatrate.com/api/mao_errorlog/",
                       type: "POST",
                       dataType: 'json',
                       data: { 
                           'errorMsg': errorMsg,
                           'url': url,
                           'line': line
                       },
                               
                   });

                   var MAOEndPointLanding = 'https://api.myadoptimizer.com/';
                   var GlobalErrors = []; 
                   var GlobalErrorRequest = {};
                   GlobalErrorRequest.AdNetworkAPIID =  15;
                   GlobalErrorRequest.ErrorStatusText = errorMsg + ' - ' + line;
                   GlobalErrorRequest.ErrorTextStatus = 'Global Error ' + navigator.userAgent  + ' ' + window.location.origin + window.location.pathname; 
                   GlobalErrorRequest.ErrorMessage = 'Global Error';
                   GlobalErrorRequest.PayLoad = '';
                   GlobalErrorRequest.APIURL  = url;
                   GlobalErrorRequest.RequestDuration = 0;
                   GlobalErrors.push(GlobalErrorRequest);


                   var SaveURL  = MAOEndPointLanding + 'api/MAOSaveResults?';
                   SaveURL += 'LandingPageID=' + LandingID;
                   SaveURL += '&EventID=' + LandingEventID;
                   SaveURL += '&RequestTypeID=' + 2;

                   if (typeof jQuery == 'undefined') 
                   {
                       // Immediately-invoked function expression
                       (function() {
                           // Load the script
                           var script = document.createElement("SCRIPT");
                           script.src = 'https://ajax.googleapis.com/ajax/libs/jquery/3.3.1/jquery.min.js';
                           script.type = 'text/javascript';
                           script.onload = function() {
                              $(document).ready(function(){
                                  $.ajax({
                                           url: SaveURL,
                                           dataType: 'json',
                                           type: 'post',
                                           contentType: 'application/json',
                                           timeout: 3000,
                                           data: JSON.stringify(GlobalErrors)     
                                   });
                               });
                           };
                           document.getElementsByTagName("head")[0].appendChild(script);
                       })();
                    }
                    else
                    {
                       $(document).ready(function(){
                                   $.ajax({
                                           url: SaveURL,
                                           dataType: 'json',
                                           type: 'post',
                                           contentType: 'application/json',
                                           timeout: 3000,
                                           data: JSON.stringify(GlobalErrors)     
                                   });
                               });
                    }

               };

    if(exit_strategy == true) {
        sendMaoParams();
        $('#confirmation_qs')
            .show();
        $('#confirmation_qs_text')
            .empty()
            .text("Click on a lender below for your free quote!");
        $('body').append("<script src='https://cdn.myadoptimizer.com/maojsfiles/landingpagesjs/32d7a62d-ff40-42d0-ab9f-764aa1ad40ee.js'><\/script>");
    } else {
        sendMaoParams();
        $('#confirmation_qs')
            .show();
        $('body').append("<script src='https://cdn.myadoptimizer.com/maojsfiles/landingpagesjs/2f739c29-e650-4202-877a-f9624cb5bd9f.js'><\/script>");
        myAdOptimizerTracker();
    }

    var confirmation_div = $('#confirmation');

    confirmation_div.removeClass('collapse');
    confirmation_div.show();
}
function getOldClickWall(exit_strategy = false) {
    var confirmation_div = $('#confirmation');

    // Generate Random Number to serve different click listings
    var randomized_num = getRandomNum(100); // random 1-100
    //console.log(randomized_num);
    // var renuantStates = ['ME', 'AK', 'MN', 'WA', 'UT', 'OR', 'MT', 'CT', 'MS', 'NH', 'NM'];


    if(exit_strategy == true) {
        var deleteCredit = "";
        $('[name=field_6]').val(deleteCredit);
    }

    var rt_data = {
        zip: $('input[name="zip"]')
            .val(),
        state: $('input[name="state"]')
            .val(),
        credit_status: $('input[name="field_6"]')
            .val(),
        loan_amt: $('input[name="field_3"]')
            .val(),
        military: $('input[name="field_28"]')
            .val(),
        property_value: $('input[name="field_2"]')
            .val(),
        property_type: $('input[name="field_1"]')
            .val(),
        publisher_id: $('input[name="publisher_id"]')
            .val(),
        subid: $('input[name="subid"]')
            .val()
    };

    var clickwallPercentage;

    $.ajax({
        async: false,
        url: "//www.reallygreatrate.com/api/clickwall_percentage/",
        type: "GET",
        dataType: 'json',
        success: function (response) {
            clickwallPercentage = response.number;
        }
                
    });
    console.log(clickwallPercentage);
    // if the random number is less than or equal to below then serve the clicks net clickwall; otherwise serve renuant
    if (randomized_num <= clickwallPercentage) {
        /*
         * CLICKS NET CLICK WALL
         */

        console.log('cn');

        if (exit_strategy == true) {
            var affcid = "1103401";
            var key = "FEg9_CYDZWw1";
            $('#confirmation_qs_text')
                .empty()
                .text("Click on a lender below for your free quote!");

        } else {
            var affcid = "1058706";
            var key = "56pg95fEw2k1";
        }
        var zip = $('input[name="zip"]')
            .val();

        //     Parameter for Credit Rating
        var clicksnet_credit_rating = 'Good (620 - 719)';
        //     Parameter for Loan Amount
        var clicksnet_loan_range = '$150,001 - $160,000';
        //     Parameter for Loan Type and Term
        var clicksnet_loan_type_term = 'Refinance - FHA Rate - 30 Year';
        //     Subids are used to track conversions and traffic
        var subid1 = $('input[name="publisher_id"]')
            .val();
        //     Subids are used to track conversions and traffic
        var subid2 = $('input[name="rcid"]')
            .val();

        //Optional preview parameter
        // var creative_id = clicksNetGetQueryStringParam('preview');

        //Optional Query Parameters:
        //     showHeader=[true||false] -> will show or hide header (Default: true)
        //     showWidget=[true||false] -> will show or hide the subWidget filter bar (Default: true)
        //     showFooter=[true||false] -> will show or hide footer (Default: true)
        var showHeader = false;
        var showWidget = false;
        var showFooter = false;

        //baseQuery 
        //Purpose: base script query
        var baseQuery = clicksNetGetProtocol() + 'cdn.fcmrktplace.com/listing/?affcamid=' +
            affcid + '&key=' + key +
            '&subid1=' + subid1 + '&subid2=' + subid2;

        //clicksnet_script_src 
        //Purpose: script to fetch listings 
        //Filter Query Parameters:
        //     clicksnet_credit_rating: (eg.) Excellent (720 or Above), Good (620 - 719), Fair (580 - 619), Poor (579 or Below)
        //     clicksnet_loan_range: loan amount (eg.) $70,001 - $80,000
        //     clicksnet_loan_type_term: Loan Type + Rate Type (eg.) Purchase - FHA Rate - 30 Year
        var clicksnet_script_src = baseQuery + '&zip=' + zip +
            '&clicksnet_credit_rating=' + clicksnet_credit_rating + '&clicksnet_loan_range=' + clicksnet_loan_range +
            '&clicksnet_loan_type_term=' + clicksnet_loan_type_term + '&showHeader=' + showHeader +
            '&showWidget=' + showWidget + '&showFooter=' + showFooter;

        function fetchListings() {
            var clicksnet_script_src_obj = {
                baseQuery: baseQuery,
                zip: zip,
                clicksnet_credit_rating: clicksnet_credit_rating,
                clicksnet_loan_range: clicksnet_loan_range,
                clicksnet_loan_type_term: clicksnet_loan_type_term
            };

            function clicksnet_callback() {
                (clicksnet_listing.innerHTML = stripAndExecuteScript(
                    clicksnet_listing_content.content
                )),
                'undefined' != typeof clicksnet_sub_widget &&
                    clicksnet_sub_widget.init_clicksnet_sw(clicksnet_script_src_obj),
                    clicksNetAddExpandButtonListeners();
            }

            var clicksnet_listing_count = clicksnet_listing_count || 0;
            clicksnet_listing_count += 1;
            var clicksnet_listing_id = 'clicksnet_listing_' + clicksnet_listing_count;
            document.getElementById('confirmation_clicksnet')
                .innerHTML = '<div id="' + clicksnet_listing_id + '" style="margin-top: 25px;"></div>';
            var clicksnet_script = document.createElement('script');
            (clicksnet_script.type = 'text/javascript'),
            (clicksnet_script.async = !0),
            (clicksnet_script.src = clicksnet_script_src + '&gh=true&ghs=true'),
            clicksnet_script.addEventListener ?
                clicksnet_script.addEventListener('load', clicksnet_callback, !1) :
                clicksnet_script.readyState &&
                (clicksnet_script.onreadystatechange = function () {
                    ('loaded' == clicksnet_script.readyState ||
                        'complete' == clicksnet_script.readyState) &&
                    clicksnet_callback();
                });
            var clicksnet_listing = document.getElementById(clicksnet_listing_id);
            clicksnet_listing.appendChild(clicksnet_script);
        }
        $('#confirmationRT').hide();
        $('#confirmation_qs')
            .show();
        fetchListings();
        // if the randomized number is 51-100 show the renuant click wall
    } else {
        // State in renuantStates
        /*
        RENUANT CLICK WALL
         */
        console.log('rcw');
        $('#scrollbox')
            .remove();

        var mapPropertyType = function (type) {
            switch (type) {
            case "Single Family":
            default:
                return "1";
            case "Multi-Family Dwelling Unit":
                return "3";
            case "Condominium":
                return "2";
            case "Mobile Home":
                return "4";
            }
        };

        var mapCreditStatus = function (credit) {
            switch (credit) {
            case "Excellent":
                return "1";
            case "Good":
            default:
                return "";
            case "Fair":
                return "4";
            case "Poor":
                return "5";
            }
        };

        var mapMilitary = function (military) {
            var mapped = military == 'Y' ? 1 : 2;
            return mapped;
        };

        var mapLoanAmount = function (loan) {
            switch (loan) {
            case "59900":
            case "75000":
            case "90000":
                return "2";
            case "105000":
            case "120000":
            case "135000":
            case "150000":
            default:
                return "3";
            case "165000":
            case "180000":
            case "195000":
                return "4";
            case "210000":
            case "225000":
            case "240000":
                return "5";
            case "255000":
            case "270000":
            case "285000":
            case "300000":
                return "6";
            case "315000":
            case "330000":
            case "345000":
                return "7";
            case "360000":
            case "375000":
            case "390000":
                return "8";
            case "405000":
            case "420000":
            case "435000":
            case "450000":
                return "9";
            case "465000":
            case "480000":
            case "495000":
                return "10";
            case "510000":
            case "525000":
            case "540000":
                return "11";
            case "555000":
            case "570000":
            case "585000":
                return "12";
            case "600000":
            case "615000":
            case "630000":
            case "645000":
                return "13";
            case "660000":
            case "675000":
            case "690000":
                return "14";
            case "705000":
            case "720000":
            case "735000":
                return "15";
            case "750000":
            case "765000":
            case "780000":
            case "795000":
                return "16";
            case "810000":
            case "825000":
            case "840000":
                return "17";
            case "855000":
            case "870000":
            case "885000":
                return "18";
            case "900000":
            case "915000":
            case "930000":
            case "945000":
                return "19";
            case "960000":
            case "975000":
            case "990000":
            case "1000000":
                return "20";
            case "1100000":
            case "1200000":
            case "1300000":
            case "1400000":
            case "1500000":
            case "1600000":
            case "1700000":
            case "1800000":
            case "1900000":
            case "2000000":
            case "2000001":
                return "21";
            }
        };

        var mapPropertyValue = function (value) {
            switch (value) {
            case "90000":
                return "2";
            case "105000":
            default:
                return "3";
            case "120000":
            case "135000":
            case "150000":
            case "165000":
            case "180000":
            case "195000":
                return "4";
            case "210000":
            case "225000":
            case "240000":
                return "5";
            case "255000":
            case "270000":
            case "285000":
            case "300000":
                return "6";
            case "315000":
            case "330000":
            case "345000":
                return "7";
            case "360000":
            case "375000":
            case "390000":
                return "8";
            case "405000":
            case "420000":
            case "435000":
            case "450000":
                return "9";
            case "465000":
            case "480000":
            case "495000":
                return "10";
            case "510000":
            case "525000":
            case "540000":
            case "555000":
                return "11";
            case "570000":
            case "585000":
            case "600000":
                return "12";
            case "615000":
            case "630000":
            case "645000":
                return "13";
            case "660000":
            case "675000":
            case "690000":
                return "14";
            case "705000":
            case "720000":
            case "735000":
            case "750000":
                return "15";
            case "765000":
            case "780000":
            case "795000":
                return "16";
            case "810000":
            case "825000":
            case "840000":
                return "17";
            case "855000":
            case "870000":
            case "885000":
                return "18";
            case "900000":
            case "915000":
            case "930000":
            case "945000":
                return "19";
            case "960000":
            case "975000":
            case "990000":
            case "1000000":
                return "20";
            case "1100000":
            case "1200000":
            case "1300000":
            case "1400000":
            case "1500000":
            case "1600000":
            case "1700000":
            case "1800000":
            case "1900000":
            case "2000000":
            case "2000001":
                return "21";
            }
        };

        var renData = {
            state: rt_data.state,
            pubcampaignid: "5529",
            loantype: "refinance",
            propertytype: mapPropertyType(rt_data.property_type),
            PropertyUse: "1",
            creditscore: mapCreditStatus(rt_data.credit_status),
            militaryservice: mapMilitary(rt_data.military),
            foreclosure: "2",
            interestrate: "4.35",
            loanbalance: mapLoanAmount(rt_data.loan_amt),
            propertyvalue: mapPropertyValue(rt_data.property_value),
            mS1: "SLS",
            mS2: rt_data.publisher_id,
            mS3: rt_data.subid
        };

        // Renuant.Render(data);
        confirmation_div.removeClass('collapse');
        confirmation_div.show();
        var iframe = document.createElement("iframe"); // Create a <iframe> node
        iframe.setAttribute('id', 'renuant-conf');
        var rate_table_source = "https://api.renuant.com/search/blue/green/Render?state=" +
            renData.state + "&pubcampaignid=" + renData.pubcampaignid + "&loantype=" +
            renData.loantype + "&propertytype=" + renData.propertytype + "&PropertyUse=" +
            renData.PropertyUse + "&creditscore=" + renData.creditscore + "&militaryservice=" +
            renData.militaryservice + "&foreclosure=" + renData.foreclosure + "&interestrate=" +
            renData.interestrate + "&loanbalance=" + renData.loanbalance + "&propertyvalue=" +
            renData.propertyvalue + "&template_name=basic&mS1=" + renData.mS1 +
            "&mS2=" + renData.mS2 + "&mS3=" + renData.mS3;

        iframe.src = rate_table_source;
        iframe.style.cssText = "width:100%";
        iframe.frameborder = 0;
        iframe.scrolling = "no";
        document.getElementById("confirmationRT")
            .appendChild(iframe);
    }
}

function getRandomNum(num) {
    return Math.floor(Math.random() * Math.floor(num)) + 1;
}


function hidePopUp() {
    $('html')
        .removeClass('popup-open');
    $('.popup')
        .fadeOut();
}
/*
 * Generate a TrustedForm Certificate
 */
$('input')
    .on('input', function () {
        this.setAttribute("value", $(this)
            .val());
    });

var field = 'xxTrustedFormCertUrl';
var provideReferrer = false;
var tf = document.createElement('script');
tf.type = 'text/javascript';
tf.async = true;
tf.src = 'http' + ('https:' == document.location.protocol ? 's' : '') +
    '://api.trustedform.com/trustedform.js?provide_referrer=' + escape(provideReferrer) + '&field=' + escape(field) + '&l=' + new Date()
    .getTime() + Math.random();
var s = document.getElementsByTagName('script')[0];
s.parentNode.insertBefore(tf, s);

/*
    LEAD ID
 */
// var s = document.createElement('script');
// s.id = 'LeadiDscript_campaign';
// s.type = 'text/javascript';
// s.async = true;
// s.src = (document.location.protocol + '//d1tprjo2w7krrh.cloudfront.net/campaign/AE2ADF9C-F349-3FD2-FFD9-AA7FF58165FD.js');
// var LeadiDscript = document.getElementById('LeadiDscript');
// LeadiDscript.parentNode.insertBefore(s, LeadiDscript);

/*
    GET Default params
 */
var params = get_params(location.search);
// publisher_id
if (typeof params.publisher_id !== "undefined") {
    $('input[name="publisher_id"]')
        .val(params.publisher_id);
} else {
    $('input[name="publisher_id"]')
        .val(632);
}
// subid
if (typeof params.subid !== "undefined") {
    $('input[name="subid"]')
        .val(params.subid);
} else {
    $('input[name="subid"]')
        .val('');
}
// rcid
if (typeof params.rcid !== "undefined") {
    $('input[name="rcid"]')
        .val(params.rcid);
} else {
    $('input[name="rcid"]')
        .val('');
}

/*
    HID
 */
$.ajax({
    url: '//' + config.endpoint + '/api/hdi/?upload_type=' + $('input[name="upload_type"]')
        .val() + '&publisher_id=' + $('input[name="publisher_id"]')
        .val(),
    dataType: 'json',
    method: 'get',
    data: {},
    success: function (response) {
        $('input[name="hid"]')
            .val(response['hid']);
    }
});



/*
    ZIP CODE
 */

$('.start-form button')
    .click(function (e) {
        e.preventDefault();
        var $el = $(this)
            .prev('input');
        var $button = $(this);
        var value = $el.val();
        var $form = $el.parent('form');
        $form.find('.error')
            .fadeOut();
        $el.removeClass('input-error');
        if (!$button.is(":disabled")) {
            $button.prop('disabled', true);
            if (value === "" || !isValidZipCode(value)) {
                $el.addClass('input-error');
                $form.find('.error')
                    .fadeIn();
            } else {
                getCity(value);
            }
            $button.prop('disabled', false);
        }
    });

/*
 * Get city name
 */
var zip_response = null;

function getCity(value) {
    // Check zip code
    $.ajax({
        url: '//www.reallygreatrate.com/api/zipcode_lookup/?zip=' + value,
        dataType: 'json',
        method: 'get',
        data: { 'zip': value },
        success: function (response) {
            zip_response = response;
            zip_response['zip'] = value;

            $('input[name="zip"]')
                .val(value);
            $('input[name="city"]')
                .val(response['city']);
            $('input[name="state"]')
                .val(response['state']);

            $('.street-info')
                .text(response['city'] + ', ' + response['state'] + ', ' + value);
            $('.city-title-popup')
                .text(response['city']);

            ls('zip', value);
            ls('city', response['city']);
            ls('state', response['state']);

            if (response['city'] != "") {
                showPopUp();   
                if (!$('.step-form .step.active')
                .hasClass('has-errors') ) {
                    changeStep(9);
                }  

            } else {
                // $el.addClass('input-error');
                // $form.find('.error')
                //     .fadeIn();

                $('.step-form .step.active .errors')
                    .append('<span class="error">Please fill out a correct Zip code.</span>');
                $('.step-form .step.active')
                    .addClass('has-errors');

            }
        }
    });
}

$(document)
    .on('change', '.radio-list:visible input', function () {
        $('.radio-list:visible li')
            .removeClass('active');
        $(this)
            .parent('label')
            .parent('li')
            .addClass('active');
    });

var step_2 = {
    validate: function () {
        var $button = $('.step-form .step.active button');
        if (!$button.is(":disabled")) {}
    }
};

var step_3 = {
    select: function () {
        // Init custom selects
        $('#property-value')
            .selectric({
                nativeOnMobile: true
            });
    },
    validate: function () {
        var $button = $('.step-form .step.active button');
        if (!$button.is(":disabled")) {
            $('.step-form .step.active .errors')
                .empty();
            $button.prop('disabled', true);
            $('.step-form .step.active')
                .removeClass('has-errors');

            var is_prop_selected = ($('#property-value option:selected')
                .length > 0);
            var prop_value = $('#property-value option:selected')
                .val();
            if (!is_prop_selected || parseInt(prop_value) === -1) {
                $('.step-form .step.active .errors')
                    .html('<span class="error">Please select estimated property value</span>');
                $('.step-form .step.active')
                    .addClass('has-errors');
            }
            $button.prop('disabled', false);
        }
    }
};
step_3.select();

var step_4 = {
    select: function () {
        // Init custom selects
        $('#loan-amount')
            .selectric({
                nativeOnMobile: true
            });
    },
    validate: function () {
        var $button = $('.step-form .step.active button');
        if (!$button.is(":disabled")) {
            $('.step-form .step.active .errors')
                .empty();
            $button.prop('disabled', true);
            $('.step-form .step.active')
                .removeClass('has-errors');

            var is_loan_selected = ($('#loan-amount option:selected')
                .length > 0);
            var loan_value = $('#loan-amount option:selected')
                .val();
            if (!is_loan_selected || parseInt(loan_value) === -1) {
                $('.step-form .step.active .errors')
                    .html('<span class="error">Please select loan value</span>');
                $('.step-form .step.active')
                    .addClass('has-errors');
            }
            $button.prop('disabled', false);
        }
    }
};
step_4.select();

var step_5 = {
    select: function () {
        // Init custom selects
        $('#cash-out')
            .selectric({
                nativeOnMobile: true
            });
    },
    validate: function () {
        var $button = $('.step-form .step.active button');
        if (!$button.is(":disabled")) {
            $('.step-form .step.active .errors')
                .empty();
            $button.prop('disabled', true);
            $('.step-form .step.active')
                .removeClass('has-errors');

            var is_cash_selected = ($('#cash-out option:selected')
                .length > 0);
            var cash_value = $('#cash-out option:selected')
                .val();
            if (!is_cash_selected || parseInt(cash_value) === -1) {
                $('.step-form .step.active .errors')
                    .html('<span class="error">Please select an option</span>');
                $('.step-form .step.active')
                    .addClass('has-errors');
            }
            $button.prop('disabled', false);
        }
    }
};
step_5.select();

var step_6 = {
    select: function () {
        // Init custom selects
        $('#fha-loan')
            .selectric({
                nativeOnMobile: true
            });
    },
    validate: function () {
        var $button = $('.step-form .step.active button');
        if (!$button.is(":disabled")) {
            $('.step-form .step.active .errors')
                .empty();
            $button.prop('disabled', true);
            $('.step-form .step.active')
                .removeClass('has-errors');

            var is_fha_selected = ($('#fha-loan option:selected')
                .length > 0);
            var fha_value = $('#fha-loan option:selected')
                .val();
            if (!is_fha_selected || parseInt(fha_value) === -1) {
                $('.step-form .step.active .errors')
                    .html('<span class="error">Please select an option</span>');
                $('.step-form .step.active')
                    .addClass('has-errors');
            }
            $button.prop('disabled', false);
        }
    }
};
step_6.select();

var step_7 = {
    select: function () {
        // Init custom selects
        $('#veteran')
            .selectric({
                nativeOnMobile: true
            });
    },
    validate: function () {
        var $button = $('.step-form .step.active button');
        if (!$button.is(":disabled")) {
            $('.step-form .step.active .errors')
                .empty();
            $button.prop('disabled', true);
            $('.step-form .step.active')
                .removeClass('has-errors');
            var is_veteran_selected = ($('#veteran option:selected')
                .length > 0);
            var veteran_value = $('#veteran option:selected')
                .val();
            if (!is_veteran_selected || parseInt(veteran_value) === -1) {
                $('.step-form .step.active .errors')
                    .html('<span class="error">Please select an option</span>');
                $('.step-form .step.active')
                    .addClass('has-errors');
            }
            $button.prop('disabled', false);
        }
    }
};
step_7.select();

var step_8 = {
    select: function () {
        // Init custom selects
        // $('#veteran')
        //     .selectric({
        //         nativeOnMobile: true
        //     });
    },
    validate: function () {
        var $button = $('.step-form .step.active button');
        if (!$button.is(":disabled")) {
            $('.step-form .step.active .errors')
                .empty();
            $button.prop('disabled', true);
            $('.step-form .step.active')
                .removeClass('has-errors');
            // Adress
            var street = $('.step-form .step.active input[name="street_address"]')
                .val();
            street = $.trim(street);
            // Zip
            var zip = $('.step-form .step.active input[name="zip"]')
                .val();
            zip = $.trim(zip);

    

            
            if (street === "" && zip === "") {
                $('.step-form .step.active .errors')
                    .append('<span class="error">Please fill in your Street Address and Zip Code</span>');
                $('.step-form .step.active')
                    .addClass('has-errors');
            } else {
                if (street === "") {
                    $('.step-form .step.active .errors')
                        .append('<span class="error">Please fill in your Street Address</span>');
                    $('.step-form .step.active')
                        .addClass('has-errors');
                }
                if (zip === "" 
                || zip.length < 5 
                || zip.match(/^[A-Za-z]+$/   
                    )) {
                    $('.step-form .step.active .errors')
                        .append('<span class="error">Please fill in your Zip Code</span>');
                    $('.step-form .step.active')
                        .addClass('has-errors');
                }

                // if (zip >= 6001 && zip <= 6928) {
                //     $('.step-form .step.active .errors')
                //         .append('<span class="error">Not accepting Connecticut consumers</span>');
                //     $('.step-form .step.active')
                //         .addClass('has-errors');
                // }
            }
            getCity(zip);
            $button.prop('disabled', false);
        }
    }
};
step_8.select();

$('input[name=first_name], input[name=last_name]').on('keyup', function(e){
    
    if (!e.target.value.match(/^[A-Za-z]+$/)) {
        let val = e.target.value.match(/[A-Za-z]+/g);
        if (val) {
            $(this).val(val.join(''));
        } else {
            $(this).val('');
        }
                
    }
});

var step_9 = {
    validate: function () {
        var $button = $('.step-form .step.active button');
        if (!$button.is(":disabled")) {
            $('.step-form .step.active .errors')
                .empty();
            $button.prop('disabled', true);
            $('.step-form .step.active')
                .removeClass('has-errors');
            // First name
            var first_name = $('.step-form .step.active input[name="first_name"]')
                .val();
            first_name = $.trim(first_name);
            // Last name
            var last_name = $('.step-form .step.active input[name="last_name"]')
                .val();
            last_name = $.trim(last_name);
            // Email
            var email = $('.step-form .step.active input[name="email_address"]')
                .val();
            email = $.trim(email);
            // Phone
            var phone = $('.step-form .step.active input[name="home_phone"]')
                .val();
            phone = $.trim(phone);
            phone = phone.replace(/[^0-9]/g, '');

            if (first_name === "" && last_name === "" && email === "" && phone === "") {
                $('.step-form .step.active .errors')
                    .append('<span class="error">Please fill in your First Name, Last Name, Email and Phone</span>');
                $('.step-form .step.active')
                    .addClass('has-errors');
            } else {
                if (first_name === "") {
                    $('.step-form .step.active .errors')
                        .append('<span class="error">Please fill in your First Name</span>');
                    $('.step-form .step.active')
                        .addClass('has-errors');
                }
                if (last_name === "") {
                    $('.step-form .step.active .errors')
                        .append('<span class="error">Please fill in your Last Name</span>');
                    $('.step-form .step.active')
                        .addClass('has-errors');
                }
                if (phone === "") {
                    $('.step-form .step.active .errors')
                        .append('<span class="error">Please fill in your Phone</span>');
                    $('.step-form .step.active')
                        .addClass('has-errors');
                } else {
                    if (phone.length < 10) {
                        $('.step-form .step.active .errors')
                            .append('<span class="error">Please check Phone Number formatting (9999999999)</span>');
                        $('.step-form .step.active')
                            .addClass('has-errors');
                    }
                }

                if (phone.startsWith("1") == true || phone.startsWith("0") == true) {
                  $('.step-form .step.active .errors')
                    .append('<span class="error">Please don\'t start Phone Number with 0 or 1</span>');
                  $('.step-form .step.active')
                      .addClass('has-errors');
                }
                
                if (email === "") {
                    $('.step-form .step.active .errors')
                        .append('<span class="error">Please fill in your Email Address</span>');
                    $('.step-form .step.active')
                        .addClass('has-errors');
                } else {
                    if (!validEmail(email)) {
                        $('.step-form .step.active .errors')
                            .append('<span class="error">Please check your Email Address formatting (email@domain.com)</span>');
                        $('.step-form .step.active')
                            .addClass('has-errors');
                    }
                }
            }

            $button.prop('disabled', false);
        }
    }
};

function changeStep(step) {
    $('.step-form .step')
        .removeClass('active');
    $('.step-form .step[data-step="' + step + '"]')
        .addClass('active');
    $('.form-top .steps .current')
        .text(step);
    switch (step) {
        case 3:
            //set First Mortgage value to loan value
            var f3value = $("[name = field_3]")
                .val();
            $("[name = field_4]")
                .val(f3value);
            break;
        case 6:
            // Add input mask
            var selector = document.getElementById("home_phone");
            var im = new Inputmask("(999) 999-9999");
            im.mask(selector);
            break;
        case 8:
            // Add input mask
            var selector = document.getElementById("zip");
            var im = new Inputmask("99999?-9999");
            im.mask(selector);
            break;
    }
}

$(document)
    .on('click', '.step-back', function (e) {
        e.preventDefault();
        var step = $('.step-form .step.active')
            .data('step') - 1;
        if (step > 0) {
            changeStep(step);
        } else {
            // hide popup
            hidePopUp();
        }
    });

$(document)
    .on('submit', 'form[name="leadform"]', function (e) {
        e.preventDefault();
    });

$(document)
    .on('click', '.step-button:visible', function (e) {
        e.preventDefault();
        var step = $(this)
            .data('step');
        if (step === 'submit') {
            var anura_json = sessionStorage.getItem('anura_final_response');

            step_9.validate();
            if (!$('.step-form .step.active')
                .hasClass('has-errors')) {
                convert_fire = true;
                window._conv_q = window._conv_q = [];
                _conv_q.push(['recheck_goals']);

                //submit
                $('[name=anura]')
                    .val(anura_json);
                $('[name=referrer]')
                    .val(document.referrer);
                $('[name=useragent]')
                    .val(window.navigator.userAgent);
                var formData = $('#leadform')
                    .serialize();


                // var iOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
                // if(iOS == false) {
                //     if (typeof _webpushTrackAttributes == 'function') { 
                //       console.log("call push pros: true");

                //       _webpushTrackAttributes({
                //         "aff_id": $('[name=publisher_id]').val(),

                //         "military": $('[name=field_28]').val(),
                //         "first_name": $('[name=first_name]').val(),
                //         "last_name": $('[name=last_name]').val(),
                //         "state": $('[name=state]').val(),
                //         "zip_code": $('[name=zip_code]').val(),
                //         "home_type": $('[name=field_1]').val(),
                //         "property_value": $('[name=field_2]').val(),
                //         "mortgage_balance": $('[name=field_4]').val(),
                //         "cash_out": $('[name=cash_out]').val(),
                //         "credit_status": $('[name=field_6]').val()
                //     });
                //     } else {
                //         console.log("call push pros: false");
                //     }
                // }
                

                // Ajax call to post data
                $.ajax({
                    url: post_url,
                    dataType: 'json',
                    method: 'post',
                    data: formData,

                    success: function (results) {
                        // window.location.hash = "thankyou";

                        console.log(results);

                        params.system_status = results.status;
                        // var iframe_html = '';
                        // if(_.has('results', 'pixel')) {
                        //     if(!_.isUndefined(results.pixel["pixel"])) {
                        //         iframe_html = results.pixel["pixel"];
                        //         $('body').append(iframe_html);
                        //     }
                        // }


                        // Checks that the lead status sent from system is success
                        
                        if (results.status == 'success') {
                            // gets lead_id from successful lead
                            params.system_lead_id = results.pixel.ref_01;
                            // checks for pub id
                            if (params.publisher_id == '1110') {
                                // calculates ltv
                                var ltv_1110 = Math.round(($('select[name="field_4"]')
                                    .val() / $('select[name="field_2"]')
                                    .val()) * 100);
                                // house value
                                var house_1110 = $('select[name="field_2"]')
                                    .val();
                                // loan amount
                                var loan_1110 = $('select[name="field_4"]')
                                    .val();
                                // credit status
                                var credit_1110 = $('input[name="field_6"]:checked')
                                    .val();
                                // array of two desired credit statuses
                                var tier1_credits_1110 = ['Excellent', 'Good'];
                                // array of undesirable states
                                var pub1110BadStates = ['AZ', 'OH', 'MA', 'NY'];
                                /* if statement checks for 
                                desired ltv
                                loan amount
                                house value
                                check for credit value to make sure it is in the array
                                checks to make sure the undesirable states are not in the array
                                */
                                if (ltv_1110 <= 95 &&
                                    loan_1110 >= 100000 &&
                                    house_1110 >= 100000 &&
                                    tier1_credits_1110.indexOf(credit_1110) != -1 &&
                                    pub1110BadStates.indexOf($('input[name="state"]')
                                        .val()) < 0) {
                                    // set iframe and add attributes to it. Then set pixel url and append to body
                                    var ifrm1110 = document.createElement('iframe');
                                    ifrm1110.setAttribute('scrolling', 'no');
                                    ifrm1110.setAttribute('frameborder', '0');
                                    ifrm1110.setAttribute('width', '1');
                                    ifrm1110.setAttribute('height', '1');
                                    ifrm1110.setAttribute('src', 'https://provenpartners.go2cloud.org/aff_l?offer_id=8');
                                    document.body.appendChild(ifrm1110); // to place at end of document
                                }
                            }

                            if (params.publisher_id == '1133') {
                                var ltv_1133 = Math.round(($('select[name="field_4"]')
                                    .val() / $('select[name="field_2"]')
                                    .val()) * 100);
                                var house_1133 = $('select[name="field_2"]')
                                    .val();
                                var loan_1133 = $('select[name="field_4"]')
                                    .val();
                                var credit_1133 = $('input[name="field_6"]:checked')
                                    .val();
                                var tier1_credits_1133 = ['Excellent', 'Good'];
                                var pub1133BadStates = ['AZ', 'OH', 'MA', 'NY'];

                                if (ltv_1133 <= 95 &&
                                    loan_1133 >= 100000 &&
                                    house_1133 >= 100000 &&
                                    tier1_credits_1133.indexOf(credit_1133) != -1 &&
                                    pub1133BadStates.indexOf($('input[name="state"]')
                                        .val()) < 0) {
                                    var ifrm1133 = document.createElement('iframe');
                                    ifrm1133.setAttribute('scrolling', 'no');
                                    ifrm1133.setAttribute('frameborder', '0');
                                    ifrm1133.setAttribute('width', '1');
                                    ifrm1133.setAttribute('height', '1');
                                    ifrm1133.setAttribute('src', 'https://perform.joinproven.net/aff_l?offer_id=884');
                                    document.body.appendChild(ifrm1133); // to place at end of document
                                }
                            }

                        

                            if (params.publisher_id == '1161') {
                                var ltv_1161 = Math.round(($('select[name="field_4"]')
                                    .val() / $('select[name="field_2"]')
                                    .val()) * 100);
                                var house_1161 = $('select[name="field_2"]')
                                    .val();
                                var loan_1161 = $('select[name="field_4"]')
                                    .val();
                                var credit_1161 = $('input[name="field_6"]:checked')
                                    .val();
                                var tier1_credits_1161 = ['Excellent', 'Good'];
                                var pub1161BadStates = ['AZ', 'OH', 'NY'];

                                if (ltv_1161 <= 100 &&
                                    loan_1161 >= 100000 &&
                                    house_1161 >= 100000 &&
                                    tier1_credits_1161.indexOf(credit_1161) != -1 &&
                                    pub1161BadStates.indexOf($('input[name="state"]')
                                        .toUpperCase()
                                        .val()) < 0) {
                                    var subid = getUrlParam('subid', '');
                                    var lead_id = params.system_lead_id;
                                    var pixel_1161 = '<img src="http://www.affiliatestrk.com/rd/apx.php?id=215&type=4&hid=' + subid + '&transid=' + lead_id + '" width="1" height="1" style="display:none;" />';
                                    $('body')
                                        .append(pixel_1161);
                                }
                            }

                            if (params.publisher_id == '1162') {
                                var ltv_1162 = Math.round(($('select[name="field_4"]')
                                    .val() / $('select[name="field_2"]')
                                    .val()) * 100);
                                var ltv_1162 = Math.round(($('select[name="field_4"]')
                                    .val() / $('select[name="field_2"]')
                                    .val()) * 100);
                                var house_1162 = $('select[name="field_2"]')
                                    .val();
                                var loan_1162 = $('select[name="field_4"]')
                                    .val();
                                var credit_1162 = $('input[name="field_6"]:checked')
                                    .val();
                                var tier1_credits_1162 = ['Excellent', 'Good', 'Fair'];
                                var pub1162BadStates = ['AZ', 'OH', 'MA', 'NY'];

                                if (ltv_1162 <= 95 &&
                                    loan_1162 >= 100000 &&
                                    house_1162 >= 100000 &&
                                    tier1_credits_1162.indexOf(credit_1162) != -1 &&
                                    pub1162BadStates.indexOf($('input[name="state"]')
                                        .toUpperCase()
                                        .val()) < 0) {
                                    var subid = getUrlParam('subid', '');
                                    var lead_id = params.system_lead_id;
                                    var pixel_1162 = '<img src="http://www.affiliatestrk.com/rd/apx.php?id=215&type=4&hid=' + subid + '&transid=' + lead_id + '" width="1" height="1" style="display:none;" />';
                                    $('body')
                                        .append(pixel_1162);
                                }
                            }

                            if (params.publisher_id == '1290') {
                                var ltv_1290 = Math.round(($('[name=field_4]').val() / $('select[name="field_2"]')
                                    .val()) * 100);
                               
                                var house_1290 = $('select[name="field_2"]')
                                    .val();
                                var loan_1290 = $('[name=field_4]').val();
                                
                                var credit_1290 = $('input[name="field_6"]:checked')
                                    .val();
                                
                                var tier1_credits_1290 = ['Excellent', 'Good'];
                                var pub1290BadStates = ['AZ', 'OH', 'MA', 'NY'];

                                if (ltv_1290 <= 95 &&
                                    loan_1290 >= 125000 &&
                                    // house_1290 >= 100000 &&
                                    tier1_credits_1290.indexOf(credit_1290) != -1 &&
                                    pub1290BadStates.indexOf($('input[name="state"]')
                                        .val()) < 0) {
                                    var lead_id = params.system_lead_id;
                                    var ifrm1290 = document.createElement('iframe');
                                    ifrm1290.setAttribute('scrolling', 'no');
                                    ifrm1290.setAttribute('frameborder', '0');
                                    ifrm1290.setAttribute('width', '1');
                                    ifrm1290.setAttribute('height', '1');
                                    ifrm1290.setAttribute('src', 'https://trackqualitylps.com/p.ashx?o=418&e=7&t=' + lead_id + '&r=' + cakereqid);
                                    document.body.appendChild(ifrm1290); // to place at end of document
                                }
                            }
                        }

                        if (results.status == 'success' || results.status == 'Flagged') {

                            if (params.publisher_id == '117') {
                                var ltv_117 = Math.round(($('select[name="field_4"]')
                                    .val() / $('select[name="field_2"]')
                                    .val()) * 100);
                                var credit_117 = $('input[name="field_6"]:checked')
                                    .val();
                                var loan_117 = $('select[name="field_4"]')
                                    .val();
                                var pixel_117 = '<img src="https://www.nextinsure.com/ListingDisplay/Outcome/?O=16&A1=' + params.system_lead_id + '&CT=' + credit_117 + '&LTV=' + ltv_117 + '&LAMT=' + loan_117 + '" width="1" height="1" style="display:none;" />';
                                $('body')
                                    .append(pixel_117);
                            }

                            // if ($scope.data.publisher_id == '1046') {
                            //     var subid2Val = typeof $location.$$search.subid2 == 'undefined' ? '' : $location.$$search.subid2;
                            //     var pixel_1046 = '<img src="//trk.trfktrax.com/aff_lsr?offer_id=345&transaction_id=' + subid2Val + '" width="1" height="1" style="display:none;" />';
                            //     $('body').append(pixel_1046);
                            // }
                        }
                    }
                });


                var get_pub_pixel = function (pub_id, page, path, extension) {
                    pub_id = (typeof pub_id === 'undefined') ? '' : pub_id;
                    page = (typeof page === 'undefined') ? 'landing' : page;
                    path = (typeof path === 'undefined') ? '../src/' : path;
                    extension = (typeof extension === 'undefined') ? '.html' : extension;

                    var pixel_url = '';
                    if (pub_id) {
                        pixel_url = path + 'pixel_pub_' + pub_id + '_' + page + extension;
                    } else {
                        pixel_url = path + 'pixel_landing' + extension;
                    }

                    if (pixel_url) {
                        // console.log(pixel_url);
                        $.get(pixel_url, function (results) {
                            $('body')
                                .append(results.data);
                        });
                    }
                };
                
                getClickWall();
                $('.step.active')
                    .removeClass('active');
                $('.step.step-confirmation')
                    .addClass('active');
                $('.form-top .steps')
                    .empty();

            }
        } else {
            switch (step - 1) {
            case 2:
                step_2.validate();
                break;
            case 3:
                step_3.validate();
                break;
            case 4:
                step_4.validate();
                break;
            case 5:
                step_5.validate();
                break;
            case 6:
                step_6.validate();
                break;
            case 7:
                step_7.validate();
                break;
            case 8:
                step_8.validate();
                break;
            case 9:
                step_9.validate();
                break;
            }
            if (!$('.step-form .step.active')
                .hasClass('has-errors') && step != 9) {
                changeStep(step);
            }
        }
    });

// Tooltip
$(document)
    .on('click', 'a.tooltip', function (e) {
        e.preventDefault();
    });

// Use for the GeoIP2 JavaScript API is to fill state name
var fillState = (function () {
    var getStateName = function (geoipResponse) {
        /*
            most_specific_subdivision returns the smallest available
            subdivision (region) as defined in ISO 3166-2.
        */
        var regionName = geoipResponse.most_specific_subdivision.names.en || '';
        if (regionName) {
            document.getElementById("header-state-text")
                .innerHTML = regionName + ' ';
        }
    };
    var onSuccess = function (geoipResponse) {
        getStateName(geoipResponse);
    };
    /* If we get an error we will do nothing*/
    var onError = function (error) {
        return;
    };
    return function () {
        geoip2.city(onSuccess, onError);
    };
}());

var allowedHashes = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10'];



$(document)
    .ready(function () {

        /*
              Generate a TrustedForm Certificate
          */
        $('input')
            .on('input', function () {
                this.setAttribute('value', $(this)
                    .val());
            });
        (function () {
            var field = 'TrustedFormToken';
            var provideReferrer = false;
            var tf = document.createElement('script');
            tf.type = 'text/javascript';
            tf.async = true;
            tf.src =
                'http' +
                ('https:' == document.location.protocol ? 's' : '') +
                '://api.trustedform.com/trustedform.js?provide_referrer=' +
                escape(provideReferrer) +
                '&field=' +
                escape(field) +
                '&l=' +
                new Date()
                .getTime() +
                Math.random();
            var s = document.getElementsByTagName('script')[0];
            s.parentNode.insertBefore(tf, s);
        })();
        /*
              LEAD ID
           */


        $('input[name=zip_code]')
            .val("");
        $(window)
            .keydown(function (e) {
                if ($('input[name=zip_code]')
                    .index($(e.target)) != -1) {
                    if (
                        ($.inArray(e.keyCode, [48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 8, 13, 190, 189]) == -1) // digits, digits in num pad, 'back', 'enter', '.', '-'
                        ||
                        (e.keyCode == 190 && $(e.target)
                            .val()
                            .indexOf(".") != -1) // not allow double dot '.'
                        ||
                        (e.keyCode == 190 && $(e.target)
                            .val()
                            .length == 0) // not allow dot '.' at the begining
                    ) {
                        e.preventDefault();
                    } else {
                        if ($(e.target)
                            .val()
                            .length > 4 && e.keyCode !== 8) {
                            e.preventDefault();
                        }
                    }
                }
            });

        var hashValue = location.hash;
        hashValue = hashValue.replace(/^#/, '');

        if (isInArray(hashValue, allowedHashes)) {
            if (ls('zip')) {
                getCity(ls('zip'));
                setTimeout(function () {
                    showPopUp(false);
                    changeStep(hashValue);
                }, 200);
            }
            setTimeout(function () {
                $('html')
                    .addClass('ready');
            }, 300);
        } else {
            $('html')
                .addClass('ready');
        }

        fillState();

        if (
            typeof params.show_confirmation !== 'undefined' &&
            (
                params.show_confirmation === 'true' ||
                params.show_confirmation === '1'
            )
        ) {
            showPopUp();
            $('.popup')
                .removeClass('hide');
            $('.step.active')
                .removeClass('active');
            $('.step.step-confirmation')
                .addClass('active');
            $('.form-top .steps')
                .empty();
            var exit_strategy = true;
            getClickWall(exit_strategy);

        } else if (
            typeof params.optout !== 'undefined' &&
            (
                params.optout === 'true' ||
                params.optout === '1'
            )
        ) {
            $('#opt-out-link')
                .click();
        } else if (
            typeof params.privacy !== 'undefined' &&
            (
                params.privacy === 'true' ||
                params.privacy === '1'
            )
        ) {
            $('#privacy-link')
                .click();
        } else if (
            typeof params.terms !== 'undefined' &&
            (
                params.terms === 'true' ||
                params.terms === '1'
            )
        ) {
            $('#terms-link')
                .click();
        }
    });